<template>
  <div class="service-application">
    <ClothingCollectionStandardForm :formData="eventData" class="service-content" />
    <CustomModalFooter class="service-footer d-flex justify-content-between align-items-center flex-nowrap">
      <div class="service-image">
        <img :src="eventData.displayImg" alt="service image">
      </div>
      <div class="service-info">
        <div class="name fs-18px fw-bold" v-text="getName">[寄售] 男裝</div>
        <div class="price fs-16px fw-bold" v-text="'$' + eventData.reviewPrice">$0</div>
      </div>
      <b-button variant="primary" @click="applicationClick" :disabled="!canBuy">
        <span>{{ eventData.type === 0 ? canBuy ? '我要寄售' : '未開放寄售' : canBuy ? '我要捐贈' : '未開放捐贈' }}</span>
      </b-button>
    </CustomModalFooter>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ClothingCollectionStandardForm from "@/views/share/ClothingCollectionStandardForm.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";

export default {
  name: 'ServiceApplication',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
    };
  },
  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClothingCollectionStandardForm,
    CustomModalFooter,
  },
  computed: {
    getName() {
      switch (this.eventData.type) {
        case 0:
          return '[寄售] ' + this.eventData.displayName;
        case 1:
          return '[捐贈] ' + this.eventData.displayName;
        default:
          return '資料載入錯誤';
      }
    },
    canBuy() {
      return this.eventData.status === 1;
    }
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    applicationClick() {
      this.$emit('apply', this.eventData.serial);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.service-application {
  padding: 2rem 1rem 120px;
  .service-footer {
    gap: 1rem;
  }
  .service-image {
    width: 48px;
    height: 48px;
    border: .5px solid $color-border;
    border-radius: .75rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .service-info {
    flex: 1 0;
  }
  .btn {
    width: 9rem;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.service-application {
}
</style>
  